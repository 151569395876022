import React from "react";
import moment from "moment";
import { Content, Icon, IconPeriod, useFormattingLanguage } from "@siteimprove/fancylib";
import { BigSmall } from "../../text/big-small/big-small";
import { dateToString, toFormattedDateString } from "../../../utils/locale";
import { useLabTranslations } from "../../../translations/translations";
import {
	PeriodType,
	getTranslatedPeriodType,
	getTranslatedQuarter,
	getTranslatedMonth,
} from "./presets";
import { PeriodPickerValue } from "./period-picker-selector";

export type PeriodPickerButtonProps = {
	value: PeriodPickerValue | null;
	condensed?: boolean;
};

export function PeriodPickerButton(props: PeriodPickerButtonProps): JSX.Element {
	const { value, condensed } = props;

	const locale = useFormattingLanguage();
	const [upperLabel, lowerLabel] = usePickerButtonText(value, locale, condensed);

	return (
		<Content padding="none" flexDirection="row" alignItems="center" gap="xSmall">
			<Icon>
				<IconPeriod />
			</Icon>
			{condensed ? upperLabel : <BigSmall big={upperLabel} small={lowerLabel} />}
		</Content>
	);
}

function usePickerButtonText(
	value: PeriodPickerValue | null,
	locale: string,
	condensed?: boolean
): [upper: string, lower: string | null] {
	const translations = useLabTranslations();

	if (!value) {
		return [translations.period, null];
	}

	const { start, end, type } = value;
	const period = [start, end] as [Date, Date];
	const dateRange = `${dateToString(start, locale)} - ${dateToString(end, locale)}`;
	const typeName = getTranslatedPeriodType(translations, type);

	switch (type) {
		case PeriodType.Now:
			return [
				typeName,
				`${toFormattedDateString(start, locale, "datetime", true)} - ${toFormattedDateString(
					end,
					locale,
					"datetime",
					true
				)}`,
			];

		case PeriodType.Today:
		case PeriodType.Yesterday:
			return [typeName, dateToString(start, locale)];

		case PeriodType.LastSevenDays:
		case PeriodType.LastFourteenDays:
		case PeriodType.LastThirtyDays:
		case PeriodType.LastWeek:
		case PeriodType.ThisMonth:
		case PeriodType.LastMonth:
		case PeriodType.LastYear:
		case PeriodType.ThisYear:
		case PeriodType.ThisQuarter:
		case PeriodType.LastQuarter:
			return [typeName, dateRange];
	}

	if (isFullYear(period)) {
		return [start.getFullYear().toString(10), null];
	}

	if (isFullQuarter(period)) {
		return [
			`${getTranslatedQuarter(moment(start).quarter(), translations)} ${start.getFullYear()}`,
			null,
		];
	}

	if (isFullMonth(period)) {
		return [`${getTranslatedMonth(start.getMonth(), locale)} ${start.getFullYear()}`, null];
	}

	if (isFullDay(period)) {
		return [typeName, dateToString(start, locale)];
	}

	if (type === PeriodType.Custom) {
		if (condensed) {
			return [dateRange, null];
		}
		return [typeName, dateRange];
	}

	return [typeName, dateRange];
}

function isFullYear(period: [Date, Date]): boolean {
	const [start, end] = period;
	return moment(start).startOf("year").isSame(start) && moment(end).endOf("year").isSame(end);
}

function isFullQuarter(period: [Date, Date]): boolean {
	const [start, end] = period;
	return moment(start).startOf("quarter").isSame(start) && moment(end).endOf("quarter").isSame(end);
}

function isFullMonth(period: [Date, Date]): boolean {
	const [start, end] = period;
	return moment(start).startOf("month").isSame(start) && moment(end).endOf("month").isSame(end);
}

function isFullDay(period: [Date, Date]): boolean {
	const [start, end] = period;
	return (
		moment(start).startOf("day").isSame(start) &&
		moment(end).endOf("day").isSame(end) &&
		moment(start).isSame(end, "day")
	);
}
