import React, { useEffect, useState, ReactElement, ReactNode } from "react";
import { DataObserveKey, VisualComponent } from "../../../types/fancy-base-attributes";
import { Divider } from "../../structure/divider/divider";
import { InlineText } from "../../text/inline-text/inline-text";
import { valIf, stopEvent, useUniqueId } from "../../../utils/shorthands";
import { KeyCode } from "../../../utils/keyboard-nav-utils";
import { useStaticMode } from "../../../context/fancy-context";
import * as scss from "./tabs.scss";

export type TabProps = {
	/** The header for a single tab */
	header: React.ReactNode;
	/** Content within a single tab */
	content: React.ReactNode;
	/** Executes when a single tab is pressed */
	onClick?: () => void;
	/** If true, styling applied to current tab */
	isSelected?: boolean;
	/** EMPTY! */
	id?: string;
	/** Use to generate unique strings for aria-controls */
	controls?: string;
	/** Ref of the button */
	buttonRef?: React.RefObject<HTMLButtonElement>;
	/** If false, the tab index is set to -1  */
	lastFocused?: boolean;
	/** Tab is not clickable if disabled */
	disabled?: boolean;
} & DataObserveKey;

export type TabsProps = {
	/** List of Tabs */
	tabs: TabProps[];
	/** Default selected tab */
	selectedTab: number;
	/** Execute once tabs has been initialized */
	onInit?: (initialTab: number) => void;
	/** Executes once tab has changed */
	onChange?: (selectedTab: number) => void;
	/** Callback that calls when tabs are unmounted, typically for routing control */
	onUnMount?: () => void;
	/** If true, the tabs will remain mounted after another tab is selected */
	keepTabsMounted?: boolean;
	/** Specifies how tabs look in the static mode. Defaults to `disable`. */
	inStaticMode?: "disable" | "hide" | "show";
} & DataObserveKey &
	VisualComponent;

export function Tabs(props: TabsProps): JSX.Element {
	const { className, style, inStaticMode = "disable" } = props;
	const isStatic = useStaticMode();

	const tabs =
		isStatic && inStaticMode === "disable"
			? props.tabs.map((tab) => ({ ...tab, disabled: true }))
			: props.tabs;
	const [tabBar, tabContent] = useTabSet({
		...props,
		tabs: tabs,
	});

	if (isStatic && inStaticMode === "hide") {
		return <></>;
	}

	return (
		<div data-observe-key={props["data-observe-key"]} className={className} style={style}>
			{tabBar}
			<Divider />
			{tabContent}
		</div>
	);
}

export function TabSetBar(props: { tabs: TabProps[] }): JSX.Element {
	const { tabs } = props;
	const [focusTab, setFocusTab] = useState(0);
	function focusTabButton(idx: number) {
		tabs.forEach((x, i) => {
			if (!x.buttonRef || !x.buttonRef.current) {
				return;
			}
			if (i === idx) {
				x.buttonRef.current.setAttribute("tabindex", "");
				x.buttonRef.current.focus();
			} else {
				x.buttonRef.current.setAttribute("tabindex", "-1");
			}
		});

		if (focusTab !== idx) {
			setFocusTab(idx);
		}
	}

	function keyCodeToIndex(keyCode: number, current: number, limit: number): number | null {
		switch (keyCode) {
			case KeyCode.ArrowLeft:
				return current > 0 ? current - 1 : limit;
			case KeyCode.ArrowRight:
				return current < limit ? current + 1 : 0;
			case KeyCode.End:
				return limit;
			case KeyCode.Home:
				return 0;
		}
		return null;
	}

	function handleKeyEvent(e: React.KeyboardEvent<HTMLDivElement>) {
		const newIdx = keyCodeToIndex(e.keyCode, focusTab, tabs.length - 1);

		if (newIdx !== null) {
			focusTabButton(newIdx);
			stopEvent(e);
		}
	}

	// Even though the list of tab props are already made, we need to "extend" them to manage focus
	// properly for all usages of TabSetBar (with or without the TabSet component)
	tabs.forEach((x, i) => {
		x.lastFocused = i === focusTab;

		x.buttonRef = React.createRef<HTMLButtonElement>();

		const prevClick = x.onClick;

		if (prevClick) {
			x.onClick = () => {
				prevClick();
				setFocusTab(i);
			};
		}
	});

	return (
		<div data-component="tab-set-bar" className={scss.tabSetBar}>
			<div className={scss.tabs} role="tablist" onKeyDown={handleKeyEvent}>
				{tabs && tabs.map((tab, index) => <Tab {...tab} key={index} />)}
			</div>
		</div>
	);
}

export function useTabSet(props: TabsProps): [ReactElement<TabsProps>, ReactNode] {
	const { onChange, tabs, selectedTab, onInit, onUnMount, keepTabsMounted } = props;
	const [loadedTabs, setLoadedTabs] = useState(tabs.map((x, i) => i === selectedTab));
	const idPrefix = useUniqueId("tab");

	const tabProps: TabProps[] = tabs.map((x, i) => {
		return {
			...x,
			onClick: () => {
				if (onChange) onChange(i);
				x.onClick?.();
			},
			isSelected: i === selectedTab,
			controls: idPrefix + "-panel-" + i,
			id: idPrefix + "-button-" + i,
		};
	});

	useEffect(() => {
		if (onInit) {
			onInit(selectedTab);
		}
		if (onUnMount) {
			// todo: we need to add in pfg a wrapper function to replace the remove indentifier functionality
			return () => onUnMount();
		}
	}, []);

	useEffect(() => {
		setLoadedTabs(loadedTabs.map((x, idx) => x || idx === selectedTab));
	}, [selectedTab]);

	return [
		// eslint-disable-next-line react/jsx-key
		<TabSetBar tabs={tabProps} />,
		keepTabsMounted ? (
			tabs.map((x, idx) => (
				<div
					key={idx}
					id={idPrefix + "-panel-" + idx}
					aria-labelledby={idPrefix + "-button-" + idx}
					role="tabpanel"
					className={scss.tabpanel}
					style={{ display: idx === selectedTab ? "block" : "none" }}
				>
					{loadedTabs[idx] && x.content}
				</div>
			))
		) : (
			<div
				id={idPrefix + "-panel-" + selectedTab}
				aria-labelledby={idPrefix + "-button-" + selectedTab}
				role="tabpanel"
				className={scss.tabpanel}
			>
				{loadedTabs[selectedTab] && tabs[selectedTab].content}
			</div>
		),
	];
}

function Tab(props: TabProps): JSX.Element {
	const { header, onClick, isSelected, buttonRef, lastFocused, controls, id, disabled } = props;

	return (
		<button
			data-component="tab"
			data-observe-key={props["data-observe-key"]}
			ref={buttonRef}
			className={scss.tab}
			aria-selected={isSelected}
			aria-controls={controls}
			id={id}
			role="tab"
			onClick={onClick}
			tabIndex={valIf(!lastFocused, -1)}
			disabled={disabled}
		>
			{reactNodeToArray(header).map((e, i) => (
				<React.Fragment key={i}>
					{typeof e === "string" || typeof e === "number" ? (
						<InlineText emphasis={isSelected ? "medium" : "normal"}>{e}</InlineText>
					) : (
						e
					)}
				</React.Fragment>
			))}
		</button>
	);
}

function reactNodeToArray(r: React.ReactNode): React.ReactNodeArray {
	if (Array.isArray(r)) {
		return r;
	}
	if (isReactFragment(r)) {
		return reactNodeToArray(r.props.children);
	}
	return [r];
}
function isReactFragment(r: React.ReactNode): r is ReactElement<{ children: ReactNode }> {
	return !!r && (r as { type: symbol }).type === Symbol.for("react.fragment");
}
