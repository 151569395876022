import { createDateTimeFormatter } from "./date-time-formatter";
import { createNumberFormatter } from "./number-formatter";

interface NumberFormatInfo {
	delimiters: {
		thousands: string;
		decimal: string;
	};
}

/**
 * Gets the thousands and decimal delimiters based on the provided locale
 * @param locale
 * @returns the thousands and decimal delititers for the provided locale
 */
export function getNumberFormatInfo(locale: string): NumberFormatInfo {
	const numberFormat = createNumberFormatter(locale);
	const formatted = numberFormat.format(1000.1);

	return {
		delimiters: {
			thousands: formatted.substring(1, 2),
			decimal: formatted.substring(5, 6),
		},
	};
}

interface DateTimeFormatInfo {
	weekDays: {
		short: string[];
		long: string[];
	};
	months: {
		short: string[];
		long: string[];
	};
}

/**
 * Gets the short and long version of weekdays and months based on the provided locale
 * @param locale
 * @returns an object with the properies 'weekDays' and 'months' which contain each long and short versions of weekdays and months
 */
export function getDateTimeFormatInfo(locale: string): DateTimeFormatInfo {
	return {
		weekDays: getWeekDays(locale),
		months: getMonths(locale),
	};
}

/**
 * Gets the short and long version of weekdays based on the provided locale
 * @param locale
 * @returns an object with the properies 'short' and 'long' which string arrays with the short and long version of the weekdays are assigned to
 */
function getWeekDays(locale: string) {
	const dates = [
		new Date("1970-01-05"),
		new Date("1970-01-06"),
		new Date("1970-01-07"),
		new Date("1970-01-08"),
		new Date("1970-01-09"),
		new Date("1970-01-10"),
		new Date("1970-01-11"),
	];

	const dateFormatShort = createDateTimeFormatter(locale, { weekday: "short" });
	const dateFormatLong = createDateTimeFormatter(locale, { weekday: "long" });

	return {
		short: dates.map((date) => dateFormatShort.format(date)),
		long: dates.map((date) => dateFormatLong.format(date)),
	};
}

/**
 * Gets the short and long version of months based on the provided locale
 * @param locale
 * @returns an object with the properies 'short' and 'long' which string arrays with the short and long version of the months are assigned to
 */
function getMonths(locale: string) {
	const dates = [
		new Date("1970-01-01"),
		new Date("1970-02-01"),
		new Date("1970-03-01"),
		new Date("1970-04-01"),
		new Date("1970-05-01"),
		new Date("1970-06-01"),
		new Date("1970-07-01"),
		new Date("1970-08-01"),
		new Date("1970-09-01"),
		new Date("1970-10-01"),
		new Date("1970-11-01"),
		new Date("1970-12-01"),
	];

	const dateFormatShort = createDateTimeFormatter(locale, { month: "short" });
	const dateFormatLong = createDateTimeFormatter(locale, { month: "long" });

	return {
		short: dates.map((date) => dateFormatShort.format(date)),
		long: dates.map((date) => dateFormatLong.format(date)),
	};
}

/**
 * Converts a localized date string to a Date object. This should perform better than `Date.parse()`,
 * which doesn't handle shorter formatted dates' locales very well, often swapping month and date.
 * Read more here: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/parse
 *
 * This function assumes you used NO format options (aka `'numeric'` value for `year`, `month`, and `day`).
 * It also assumes that the string is formatted as such using the current user locale.
 *
 * @param dateStr - The date string to convert.
 * @param locale - The locale it should be formatted by
 */
export function stringToDate(dateStr: string, locale: string): Date {
	let format = deduceLocaleFormat(locale);
	// replace any symbol characters with dot
	const nonAlphanumericRegex = /[^a-zA-Z0-9]/gi;
	format = format.replace(" ", "").replace(nonAlphanumericRegex, ".");
	dateStr = dateStr.replace(" ", "").replace(nonAlphanumericRegex, ".");

	const formatParts = format.split(".");
	const dateParts = dateStr.split(".").map(Number);

	// get indices for each part, so we can index into the `dateParts`
	const dIdx = formatParts.indexOf("dd");
	const mIdx = formatParts.indexOf("MM");
	const yIdx = formatParts.indexOf("yyyy");

	// if everything went well, this should be true: 0 + 1 + 2 = 3
	if (mIdx + dIdx + yIdx !== 3) {
		console.warn(
			"Unhandled date format: The format from current locale wasn't handled by 'stringToDate(dateStr: string)'." +
				`\n\tFormat gotten: '${format}'\n\tLocale found: '${locale}'`
		);
		return new Date(dateStr); // fallback
	}

	return new Date(dateParts[yIdx], dateParts[mIdx] - 1, dateParts[dIdx]);
}

/**
 * Deduces date formatting based on a test date automatically.
 * Only intended for use with numeric values of each component of the date.
 *
 * Intended usage is for input fields where you might need to know the format to parse the string correctly.
 *
 * Examples:
 *
 * - "1.2.1901" --> "dd.mm.yyyy"
 * - "2.1.1901" --> "mm.dd.yyyy"
 * - "1/2/1901" --> "dd/mm/yyyy"
 */
export function deduceLocaleFormat(locale: string): string {
	// month is zero indexed, so 0 is January
	const format = dateToString(new Date(2020, 0, 20), locale);

	return format.replace("2020", "yyyy").replace("20", "dd").replace("01", "MM").replace("1", "MM");
}

/**
 * Converts the provided date into a date string and takes the current user locale into consideration
 * @param date date to be converted into a string
 * @param locale locale it should be formatted by
 * @param options format options for the date time
 * @returns a string based on the provided date and options
 */
export function dateToString(
	date: Date,
	locale: string,
	options?: Intl.DateTimeFormatOptions
): string {
	return removeWeirdCharacters(date.toLocaleDateString(locale, options));
}

// We need to remove the "‎" (left-to-right marker) character, since IE11 adds it to a formatted date string (Because why the frick not!?)
// The regex targets this character and space characters, since they will mess with the format parsing later on.
// This function's regex can be extended should any future edge-cases be found!
function removeWeirdCharacters(x: string) {
	const weirdCharsRegex = /&lrm;|\u200E/g;
	return x.replace(weirdCharsRegex, "");
}

export function toAccessibilityDateString(date: Date, locale: string): string {
	return dateToString(date, locale, {
		weekday: "long",
		day: "numeric",
		month: "long",
		year: "numeric",
		hour: "2-digit",
		minute: "2-digit",
	});
}

export type DateFormat = "date" | "datetime" | "time";

export function toTimeString(
	date: Date,
	locale: string,
	options?: Intl.DateTimeFormatOptions
): string {
	let localeDateString = date.toLocaleTimeString(locale, options);

	// This is necessary to align with how .net formats dates for da-DK
	// date.toLocaleDateString results in d.m.yyyy hh.mm
	// .net formatting results in dd-mm-yyyy hh:mm
	if (locale === "da-DK") {
		// replace . with : in time if format is datetime
		if (options && "hour" in options && "minute" in options) {
			localeDateString = localeDateString.replace(/.([^.]*)$/, ":$1");
		}
		// replace . in date with -
		localeDateString = localeDateString.replace(/\./g, "-");
		// add leading zeros
		localeDateString = localeDateString.replace(/\b\d\b\-/g, 0 + "$&");
	}

	return removeWeirdCharacters(localeDateString);
}

/**
 * This is the preferred way to convert a date into a string with respect to the appropriate localization.
 * @param date - The date to convert.
 * @param locale - The locale it should be formatted by.
 * @param format - Whether the string should be just date or date and time.
 * @param skipTimezoneReset - Whether the date should be interpreted to a new date object as if the internals of the first date object are UTC values.
 */
export function toFormattedDateString(
	date: Date,
	locale: string,
	format?: DateFormat,
	skipTimezoneReset?: boolean
): string {
	const dateNoTz = skipTimezoneReset
		? date
		: new Date(
				date.getUTCFullYear(),
				date.getUTCMonth(),
				date.getUTCDate(),
				date.getUTCHours(),
				date.getUTCMinutes(),
				date.getUTCSeconds()
		  );

	switch (format) {
		case "date":
			return dateToString(dateNoTz, locale);
		case "datetime":
			return dateToString(dateNoTz, locale, { hour: "2-digit", minute: "2-digit" });
		case "time":
			return toTimeString(dateNoTz, locale, { hour: "2-digit", minute: "2-digit" });
		default:
			return dateToString(dateNoTz, locale);
	}
}
