import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import {
	Button,
	cn,
	Divider,
	Icon,
	IconArrow,
	IconChevron,
	Tabs,
	useFormattingLanguage,
	UseState,
} from "@siteimprove/fancylib";
import { DatePicker, DatePickerProps } from "../date-picker/date-picker";
import { InputField } from "../../forms-and-inputs/input-field/input-field";
import * as datePickerScss from "../date-picker/date-picker.scss";
import { useLabTranslations } from "../../../translations/translations";
import {
	PeriodPickerPreset,
	useDefaultPeriodPresets,
	getMonthPreset,
	getQuarterPreset,
	getYearPreset,
	isPeriodWithinBounds,
	PeriodType,
	isPeriodEqualToPreset,
} from "./presets";
import * as scss from "./period-picker.scss";

export * from "./presets";

export type PeriodPickerValue = {
	start: Date;
	end: Date;
	type: PeriodType;
};

export type PeriodPickerSelectorProps = {
	value: PeriodPickerValue;
	onChange: (value: PeriodPickerValue) => void;
	minDate?: Date;
	maxDate?: Date;
	translations: DatePickerProps["translations"];
	hiddenPresets?: PeriodType[];
	condensed?: boolean;
};

type PeriodPickerContextValue = {
	periodState: UseState<PeriodPickerValue>;
	minDate?: Date;
	maxDate?: Date;
	hiddenPresets?: PeriodType[];
};

const PeriodPickerContext = React.createContext<PeriodPickerContextValue>({
	periodState: [{ start: new Date(), end: new Date(), type: PeriodType.Custom }, () => {}],
});

export function PeriodPickerSelector(props: PeriodPickerSelectorProps): JSX.Element {
	const {
		value,
		onChange,
		translations,
		minDate,
		maxDate = moment().endOf("day").toDate(),
		hiddenPresets = [],
		...rest
	} = props;

	// when the user clicks on the calendar, we need to know
	// whether to update the start or end date
	const i18nLab = useLabTranslations();
	const [isActingOnStartDate, setIsActingOnStartDate] = useState(true);
	const [selectedTab, setSelectedTab] = useState(0);
	const { start: startDate, end: endDate } = value;

	const ensurePeriodIsWithinBounds = (period: Date[]): Date[] => {
		const [start, end] = period;
		const newStart = minDate && moment(start).isBefore(minDate) ? minDate : start;
		const newEnd = maxDate && moment(end).isAfter(maxDate) ? maxDate : end;
		return [newStart, newEnd];
	};

	const handleCustomPeriodChange = (date: Date | Date[]) => {
		if (Array.isArray(date)) {
			onChange({ start: date[0], end: date[1], type: PeriodType.Custom });
		} else {
			const newPeriod = isActingOnStartDate
				? [moment(date).startOf("day").toDate(), moment(date).endOf("day").toDate()]
				: [startDate, moment(date).endOf("day").toDate()];
			const sortedPeriod = newPeriod.sort((a, b) => a.getTime() - b.getTime());
			const validPeriod = ensurePeriodIsWithinBounds(sortedPeriod);
			onChange({ start: validPeriod[0], end: validPeriod[1], type: PeriodType.Custom });
			setIsActingOnStartDate(!isActingOnStartDate); // toggle between acting on start and end date
		}
	};

	return (
		<PeriodPickerContext.Provider
			value={{ periodState: [value, onChange], minDate, maxDate, hiddenPresets }}
		>
			<div
				data-component="period-picker"
				className={cn(scss.periodPicker, "fancy-PeriodPickerSelector")}
				role="group"
				{...rest}
			>
				<div className={scss.presetsContainer}>
					<Tabs
						selectedTab={selectedTab}
						onChange={setSelectedTab}
						tabs={[
							{
								header: i18nLab.presets,
								content: <PresetsTab />,
							},
							{
								header: i18nLab.yearOverview,
								content: <YearOverviewTab />,
							},
						]}
					/>
				</div>
				<div className={scss.calendarsContainer}>
					<div className={scss.inputsContainer}>
						<DateInputField
							aria-label="Period start"
							name="startDate"
							value={startDate}
							onChange={(newStartDate) => {
								handleCustomPeriodChange([newStartDate, endDate]);
							}}
						/>
						<Icon rotation="270">
							<IconArrow />
						</Icon>
						<DateInputField
							aria-label="Period end"
							name="endDate"
							value={endDate}
							onChange={(newEndDate) => {
								handleCustomPeriodChange([startDate, newEndDate]);
							}}
						/>
					</div>
					<Divider />
					<DatePicker
						minDate={minDate}
						maxDate={maxDate}
						value={[startDate, endDate]}
						onChange={(newDates) => handleCustomPeriodChange(newDates)}
						translations={translations}
						tileClassName={[datePickerScss.calendarTile, scss.calendarTile]}
						minDetail="month"
						showNeighboringMonth={false}
					/>
				</div>
			</div>
		</PeriodPickerContext.Provider>
	);
}

const PresetsTab = (): JSX.Element | null => {
	const { hiddenPresets } = React.useContext(PeriodPickerContext);
	const defaultPresets = useDefaultPeriodPresets();
	const presetsToDisplay: PeriodType[] = [
		PeriodType.Now,
		PeriodType.Today,
		PeriodType.Yesterday,
		PeriodType.LastSevenDays,
		PeriodType.LastFourteenDays,
		PeriodType.LastThirtyDays,
		PeriodType.LastWeek,
		PeriodType.ThisMonth,
		PeriodType.LastMonth,
		PeriodType.ThisQuarter,
		PeriodType.LastQuarter,
		PeriodType.ThisYear,
	];

	return (
		<div className={scss.presetsTab}>
			<div className={scss.presetsGrid}>
				{presetsToDisplay.map((presetType) => {
					const preset = defaultPresets[presetType];
					if (!preset || (hiddenPresets && hiddenPresets.indexOf(preset.type) > -1)) return null;
					return <PresetButton key={preset.label} preset={preset} />;
				})}
			</div>
		</div>
	);
};

const YearOverviewTab = (): JSX.Element | null => {
	const { periodState, minDate, maxDate } = React.useContext(PeriodPickerContext);
	const period = [periodState[0].start, periodState[0].end];
	const [selectedYear, setSelectedYear] = useState<number>(moment(period[0]).year());

	const locale = useFormattingLanguage();
	const i18nLab = useLabTranslations();

	const monthPresets = Array.from(Array(12).keys()).map((month) =>
		getMonthPreset(month, selectedYear, locale, !!maxDate)
	);

	const quarterPresets = Array.from(Array(4).keys()).map((quarter) =>
		getQuarterPreset(quarter + 1, selectedYear, i18nLab, !!maxDate)
	);

	const yearPreset = getYearPreset(selectedYear, !!maxDate);

	const yearOverviewPresets = [...quarterPresets];
	// we need to transpose the month presets to match the
	// layout of the quarter presets
	for (let i = 0; i < 3; i++) {
		for (let j = 0; j < 4; j++) {
			yearOverviewPresets.push(monthPresets[j * 3 + i]);
		}
	}

	return (
		<div className={scss.yearOverviewTab}>
			<div className={scss.yearSelector}>
				<Button
					disabled={minDate && selectedYear <= moment(minDate).year()}
					onClick={() => setSelectedYear(selectedYear - 1)}
					aria-label={i18nLab.previousYear}
					variant="borderless"
				>
					<Icon rotation="90">
						<IconChevron />
					</Icon>
				</Button>
				<PresetButton preset={yearPreset} />
				<Button
					disabled={maxDate && selectedYear >= moment(maxDate).year()}
					onClick={() => setSelectedYear(selectedYear + 1)}
					aria-label={i18nLab.nextYear}
					variant="borderless"
				>
					<Icon rotation="270">
						<IconChevron />
					</Icon>
				</Button>
			</div>
			<div className={scss.yearOverviewGrid}>
				{yearOverviewPresets.map((preset) => (
					<PresetButton key={preset.label} preset={preset} />
				))}
			</div>
		</div>
	);
};

type DateInputFieldProps = {
	name: string;
	value: Date;
	onChange: (value: Date) => void;
	"aria-label": string;
};

const DateInputField = (props: DateInputFieldProps): JSX.Element => {
	const { minDate, maxDate } = React.useContext(PeriodPickerContext);
	const { value, onChange, name, ...rest } = props;
	const [innerValue, setInnerValue] = useState<string>(moment(value).format("YYYY-MM-DD"));
	const [isValid, setIsValid] = useState<boolean>(true);
	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		// set min and max date on the input field
		if (inputRef.current) {
			inputRef.current.min = minDate ? moment(minDate).format("YYYY-MM-DD") : "";
			inputRef.current.max = maxDate ? moment(maxDate).format("YYYY-MM-DD") : "";
		}
	}, [minDate, maxDate]);

	const handleOnChange = (newValue: string) => {
		setInnerValue(newValue);
		const [valid, validDate] = isValidDate(newValue, minDate, maxDate);
		setIsValid(valid);
		if (valid) {
			onChange(validDate!);
		}
	};

	useEffect(() => {
		setInnerValue(moment(value).format("YYYY-MM-DD"));
	}, [value]);

	return (
		<InputField
			value={innerValue}
			onChange={handleOnChange}
			type="date"
			name={name}
			invalid={!isValid}
			className={scss.inputWrapper}
			ref={inputRef}
			{...rest}
		/>
	);
};

type PresetButtonProps = {
	preset: PeriodPickerPreset;
};

const PresetButton = (props: PresetButtonProps): JSX.Element => {
	const { periodState, minDate, maxDate } = React.useContext(PeriodPickerContext);
	const { preset } = props;
	const [contextValue, setPeriod] = periodState;
	const { start: startDate, end: endDate } = contextValue;
	const isActive =
		contextValue.type === PeriodType.Custom
			? isPeriodEqualToPreset([startDate, endDate], preset)
			: contextValue.type === preset.type;
	const isDisabled = !isPeriodWithinBounds(preset.getPeriod(), [minDate, maxDate]);

	return (
		<Button
			disabled={isDisabled}
			aria-pressed={isActive}
			active={isActive}
			onClick={() => {
				const newPeriod = preset.getPeriod();
				setPeriod({
					start: newPeriod[0],
					end: newPeriod[1],
					type: preset.type,
				});
			}}
			variant="borderless"
			style={{ margin: 0 }}
			aria-label={preset.fullLabel}
		>
			{preset.label}
		</Button>
	);
};

function isValidDate(
	date: string,
	minDate: Date | undefined,
	maxDate: Date | undefined
): [boolean, Date | undefined] {
	const momentDate = moment(date, "YYYY-MM-DD", true);

	if (!momentDate.isValid()) {
		return [false, undefined];
	}

	if (minDate && momentDate.isBefore(minDate, "day")) {
		return [false, undefined];
	}

	if (maxDate && momentDate.isAfter(maxDate, "day")) {
		return [false, undefined];
	}

	return [true, momentDate.toDate()];
}
