import moment from "moment";
import { useFormattingLanguage } from "@siteimprove/fancylib";
import { useLabTranslations } from "../../../translations/translations";

// Follows PFG's PeriodType enum
export enum PeriodType {
	Now = 0,
	Today = 1,
	Yesterday = 2,
	LastSevenDays = 3,
	LastWeek = 4,
	LastFourteenDays = 5,
	LastMonth = 6,
	ThisMonth = 7,
	LastThirtyDays = 8,
	LastSixMonths = 9,
	ThisQuarter = 10,
	LastYear = 11,
	ThisYear = 12,
	Custom = 13,
	Last365Days = 14,
	LastQuarter = 15,
}

export type PeriodPickerPreset = {
	type: PeriodType;
	label: string;
	fullLabel?: string;
	getPeriod: () => [Date, Date];
};

export type PeriodPresetOptions = {
	includeToday?: boolean;
};

type PeriodPresetFunc = (
	locale: string,
	translations: ReturnType<typeof useLabTranslations>,
	options?: PeriodPresetOptions
) => PeriodPickerPreset;

const now: PeriodPresetFunc = (locale, translations) => ({
	type: PeriodType.Now,
	label: getTranslatedPeriodType(translations, PeriodType.Now),
	getPeriod: () => [
		moment().locale(locale).startOf("minutes").subtract(5, "minutes").toDate(),
		moment().locale(locale).endOf("minutes").toDate(),
	],
});

const today: PeriodPresetFunc = (locale, translations) => ({
	type: PeriodType.Today,
	label: getTranslatedPeriodType(translations, PeriodType.Today),
	getPeriod: () => [
		moment().locale(locale).startOf("day").toDate(),
		moment().locale(locale).endOf("day").toDate(),
	],
});

const yesterday: PeriodPresetFunc = (locale, translations) => ({
	type: PeriodType.Yesterday,
	label: getTranslatedPeriodType(translations, PeriodType.Yesterday),
	getPeriod: () => [
		moment().locale(locale).subtract(1, "days").startOf("day").toDate(),
		moment().locale(locale).subtract(1, "days").endOf("day").toDate(),
	],
});

const lastWeek: PeriodPresetFunc = (locale, translations) => ({
	type: PeriodType.LastWeek,
	label: getTranslatedPeriodType(translations, PeriodType.LastWeek),
	getPeriod: () => [
		moment().locale(locale).subtract(1, "week").startOf("week").toDate(),
		moment().locale(locale).subtract(1, "week").endOf("week").toDate(),
	],
});

const thisMonth: PeriodPresetFunc = (locale, translations, options) => ({
	type: PeriodType.ThisMonth,
	label: getTranslatedPeriodType(translations, PeriodType.ThisMonth),
	getPeriod: () => [
		moment().locale(locale).startOf("month").toDate(),
		thisXEndFunc(locale, options),
	],
});

const lastMonth: PeriodPresetFunc = (locale, translations) => ({
	type: PeriodType.LastMonth,
	label: getTranslatedPeriodType(translations, PeriodType.LastMonth),
	getPeriod: () => [
		moment().locale(locale).subtract(1, "months").startOf("month").toDate(),
		moment().locale(locale).subtract(1, "months").endOf("month").toDate(),
	],
});

const thisQuarter: PeriodPresetFunc = (locale, translations, options) => ({
	type: PeriodType.ThisQuarter,
	label: getTranslatedPeriodType(translations, PeriodType.ThisQuarter),
	getPeriod: () => [
		moment().locale(locale).startOf("quarter").toDate(),
		thisXEndFunc(locale, options),
	],
});

const lastQuarter: PeriodPresetFunc = (locale, translations) => ({
	type: PeriodType.LastQuarter,
	label: getTranslatedPeriodType(translations, PeriodType.LastQuarter),
	getPeriod: () => [
		moment().locale(locale).subtract(1, "quarters").startOf("quarter").toDate(),
		moment().locale(locale).subtract(1, "quarters").endOf("quarter").toDate(),
	],
});

const thisYear: PeriodPresetFunc = (locale, translations, options) => ({
	type: PeriodType.ThisYear,
	label: getTranslatedPeriodType(translations, PeriodType.ThisYear),
	getPeriod: () => [
		moment().locale(locale).startOf("year").toDate(),
		thisXEndFunc(locale, options),
	],
});

const lastYear: PeriodPresetFunc = (locale, translations) => ({
	type: PeriodType.LastYear,
	label: getTranslatedPeriodType(translations, PeriodType.LastYear),
	getPeriod: () => [
		moment().locale(locale).subtract(1, "years").startOf("year").toDate(),
		moment().locale(locale).subtract(1, "years").endOf("year").toDate(),
	],
});

const last7Days: PeriodPresetFunc = (locale, translations, options) => ({
	type: PeriodType.LastSevenDays,
	label: getTranslatedPeriodType(translations, PeriodType.LastSevenDays),
	getPeriod: () => [
		moment(lastXEndFunc(locale, options))
			.locale(locale)
			.subtract(6, "days")
			.startOf("day")
			.toDate(),
		lastXEndFunc(locale, options),
	],
});

const last14Days: PeriodPresetFunc = (locale, translations, options) => ({
	type: PeriodType.LastFourteenDays,
	label: getTranslatedPeriodType(translations, PeriodType.LastFourteenDays),
	getPeriod: () => [
		moment(lastXEndFunc(locale, options))
			.locale(locale)
			.subtract(13, "days")
			.startOf("day")
			.toDate(),
		lastXEndFunc(locale, options),
	],
});

const last30Days: PeriodPresetFunc = (locale, translations, options) => ({
	type: PeriodType.LastThirtyDays,
	label: getTranslatedPeriodType(translations, PeriodType.LastThirtyDays),
	getPeriod: () => [
		moment(lastXEndFunc(locale, options))
			.locale(locale)
			.subtract(29, "days")
			.startOf("day")
			.toDate(),
		lastXEndFunc(locale, options),
	],
});

const last365Days: PeriodPresetFunc = (locale, translations, options) => ({
	type: PeriodType.Last365Days,
	label: getTranslatedPeriodType(translations, PeriodType.Last365Days),
	getPeriod: () => [
		moment(lastXEndFunc(locale, options))
			.locale(locale)
			.subtract(365, "days")
			.startOf("day")
			.toDate(),
		lastXEndFunc(locale, options),
	],
});

export function useDefaultPeriodPresets(
	locale?: string,
	options?: PeriodPresetOptions
): Partial<Record<PeriodType, PeriodPickerPreset>> {
	const i18n = useLabTranslations();
	const formattingLang = useFormattingLanguage();
	const localeToUse = locale || formattingLang;

	return {
		[PeriodType.Now]: now(localeToUse, i18n, options),
		[PeriodType.Today]: today(localeToUse, i18n, options),
		[PeriodType.Yesterday]: yesterday(localeToUse, i18n, options),
		[PeriodType.LastWeek]: lastWeek(localeToUse, i18n, options),
		[PeriodType.ThisMonth]: thisMonth(localeToUse, i18n, options),
		[PeriodType.LastMonth]: lastMonth(localeToUse, i18n, options),
		[PeriodType.ThisQuarter]: thisQuarter(localeToUse, i18n, options),
		[PeriodType.LastQuarter]: lastQuarter(localeToUse, i18n, options),
		[PeriodType.ThisYear]: thisYear(localeToUse, i18n, options),
		[PeriodType.LastYear]: lastYear(localeToUse, i18n, options),
		[PeriodType.LastSevenDays]: last7Days(localeToUse, i18n, options),
		[PeriodType.LastFourteenDays]: last14Days(localeToUse, i18n, options),
		[PeriodType.LastThirtyDays]: last30Days(localeToUse, i18n, options),
		[PeriodType.Last365Days]: last365Days(localeToUse, i18n, options),
	};
}

export function getTranslatedMonth(month: number, locale: string, shortLabel = true): string {
	return shortLabel
		? moment().month(month).locale(locale).format("MMM")
		: moment().month(month).format("MMMM");
}

export function getMonthPreset(
	month: number,
	year: number,
	locale: string,
	limitToToday?: boolean
): PeriodPickerPreset {
	const now = moment();
	const nowYear = now.year();
	const nowMonth = now.month();
	const lastMonth = now.subtract(1, "months").month();
	return {
		type:
			nowYear === year && nowMonth === month
				? PeriodType.ThisMonth
				: nowYear === year && lastMonth === month
				? PeriodType.LastMonth
				: PeriodType.Custom,
		label: getTranslatedMonth(month, locale, true),
		fullLabel: getTranslatedMonth(month, locale, false),
		getPeriod: () => [
			moment().month(month).year(year).startOf("month").toDate(),
			year === moment().year() && month === moment().month() && limitToToday
				? moment().endOf("day").toDate()
				: moment().month(month).year(year).endOf("month").toDate(),
		],
	};
}

export function getTranslatedQuarter(
	quarter: number,
	translations: ReturnType<typeof useLabTranslations>
): string {
	switch ((quarter - 1) % 4) {
		case 1:
			return translations.quarter2;
		case 2:
			return translations.quarter3;
		case 3:
			return translations.quarter4;
		case 0:
		default:
			return translations.quarter1;
	}
}

export function getQuarterPreset(
	quarter: number,
	year: number,
	translations: ReturnType<typeof useLabTranslations>,
	limitToToday?: boolean
): PeriodPickerPreset {
	const now = moment();
	const nowYear = now.year();
	const nowQuarter = now.quarter();
	return {
		type: nowYear === year && nowQuarter === quarter ? PeriodType.ThisQuarter : PeriodType.Custom,
		label: getTranslatedQuarter(quarter, translations),
		getPeriod: () => [
			moment().quarter(quarter).year(year).startOf("quarter").toDate(),
			year === moment().year() && quarter === moment().quarter() && limitToToday
				? moment().endOf("day").toDate()
				: moment().quarter(quarter).year(year).endOf("quarter").toDate(),
		],
	};
}

export function getYearPreset(year: number, limitToToday?: boolean): PeriodPickerPreset {
	const now = moment();
	const nowYear = now.year();
	const lastYear = nowYear - 1;
	return {
		type:
			nowYear === year
				? PeriodType.ThisYear
				: year === lastYear
				? PeriodType.LastYear
				: PeriodType.Custom,
		label: year.toString(),
		getPeriod: () => [
			moment().year(year).startOf("year").toDate(),
			year === moment().year() && limitToToday
				? moment().endOf("day").toDate()
				: moment().year(year).endOf("year").toDate(),
		],
	};
}

export function isPeriodEqualToPreset(
	[startDate, endDate]: [Date, Date],
	preset: PeriodPickerPreset
): boolean {
	const [presetStartDate, presetEndDate] = preset.getPeriod();
	return (
		moment(startDate).isSame(presetStartDate, "day") && moment(endDate).isSame(presetEndDate, "day")
	);
}

export function isPeriodWithinBounds(
	[startDate, endDate]: [Date, Date],
	[boundStartDate, boundEndDate]: [Date | undefined, Date | undefined]
): boolean {
	if (!boundStartDate && !boundEndDate) {
		return true;
	}
	if (!boundStartDate) {
		return moment(endDate).isSameOrBefore(boundEndDate!, "day");
	}
	if (!boundEndDate) {
		return moment(startDate).isSameOrAfter(boundStartDate!, "day");
	}
	return (
		moment(startDate).isSameOrAfter(boundStartDate, "day") &&
		moment(endDate).isSameOrBefore(boundEndDate, "day")
	);
}

export function getTranslatedPeriodType(
	translations: ReturnType<typeof useLabTranslations>,
	periodType: PeriodType
): string {
	switch (periodType) {
		case PeriodType.Now:
			return translations.now;
		case PeriodType.Today:
			return translations.today;
		case PeriodType.Yesterday:
			return translations.yesterday;
		case PeriodType.LastSevenDays:
			return translations.last7Days;
		case PeriodType.LastFourteenDays:
			return translations.last14Days;
		case PeriodType.LastThirtyDays:
			return translations.last30Days;
		case PeriodType.Last365Days:
			return translations.last365Days;
		case PeriodType.LastWeek:
			return translations.lastWeek;
		case PeriodType.ThisMonth:
			return translations.thisMonth;
		case PeriodType.LastMonth:
			return translations.lastMonth;
		case PeriodType.ThisQuarter:
			return translations.thisQuarter;
		case PeriodType.LastQuarter:
			return translations.lastQuarter;
		case PeriodType.LastSixMonths:
			return translations.last6Months;
		case PeriodType.ThisYear:
			return translations.thisYear;
		case PeriodType.LastYear:
			return translations.lastYear;
		case PeriodType.Custom:
		default:
			return translations.customDate;
	}
}

export function utcToLocal(date: Date): Date {
	return new Date(
		date.getUTCFullYear(),
		date.getUTCMonth(),
		date.getUTCDate(),
		date.getUTCHours(),
		date.getUTCMinutes(),
		date.getUTCSeconds()
	);
}

function lastXEndFunc(locale: string, options?: PeriodPresetOptions) {
	const now = moment().locale(locale).endOf("minutes").toDate();
	return moment(now)
		.locale(locale)
		.add(options?.includeToday ? 0 : -1, "days")
		.endOf("day")
		.toDate();
}

function thisXEndFunc(locale: string, options?: PeriodPresetOptions) {
	const now = moment().locale(locale).endOf("minutes").toDate();
	const dayStart = moment(now).locale(locale).startOf("day").toDate();
	const monStart = moment(now).locale(locale).startOf("month").toDate();
	return monStart >= dayStart
		? moment(now).locale(locale).endOf("day").toDate()
		: lastXEndFunc(locale, options);
}
